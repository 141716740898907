// Global function object
if (typeof madeBase === "undefined") var madeBase = {};

(function ($) {
  madeBase.contentFeedSlider = $(".content-feed-slideshow").bxSlider({
    controls: false,
  });

  madeBase.getPostsNews = function () {
    if ($("body.page-news").length) {
      var getNews = new MadeGetPosts("post", ".news-results", null, true);
      getNews.run();
    }
  };

  madeBase.getPostsEvents = function () {
    if ($("body.page-events").length) {
      var getEvents = new MadeGetPosts("event", ".events-results", null, true);
      getEvents.run();
    }
  };

  madeBase.tabs = function () {
    $(".nav-tabs a").click(function (e) {
      e.preventDefault();
      $(this).tab("show");
    });
  };

  madeBase.contentBlockSlideshow = function () {
    $(".content-block-slideshow").bxSlider({
      mode: "fade",
      captions: true,
      adaptiveHeight: true,
      controls: false,
    });
  };

  madeBase.animateExpandCollapse = function () {
    $(".expand-collapse-trigger").click(function () {
      $(this).find("i").toggleClass("fa-rotate-180");
    });
  };

  madeBase.showcase = $("#showcase").bxSlider({
    mode: "fade",
    adaptiveHeight: true,
    auto: true,
    pause: 5000,
    autoHover: true,
    onSliderLoad: function () {
      var caption = $("#showcase .caption");
      var target =
        parseInt($(".container").first().css("margin-left")) +
        parseInt($(".container").first().css("padding-left"));
      caption.css("padding-left", target);
    },
  });

  if ($("#showcase").length) {
    // https://css-tricks.com/snippets/jquery/done-resizing-event/
    var resizeTimer;
    $(window).on("resize", function (e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        madeBase.showcase.reloadSlider();
      }, 250);
    });
  }

  madeBase.partnerMap = function () {
    madeBase.partnerMap.map = new google.maps.Map(
      document.getElementById("partnerMap"),
      {
        styles: [
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [
              {
                saturation: 36,
              },
              {
                color: "#333333",
              },
              {
                lightness: 40,
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#ffffff",
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#fefefe",
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#fefefe",
              },
              {
                lightness: 17,
              },
              {
                weight: 1.2,
              },
            ],
          },
          {
            featureType: "administrative.country",
            elementType: "labels.text.fill",
            stylers: [
              {
                lightness: "0",
              },
              {
                gamma: "1.00",
              },
              {
                saturation: "0",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry.fill",
            stylers: [
              {
                lightness: "-10",
              },
              {
                gamma: "1.00",
              },
              {
                saturation: "0",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "labels.text.fill",
            stylers: [
              {
                lightness: "0",
              },
              {
                gamma: "1.00",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#dedede",
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 29,
              },
              {
                weight: 0.2,
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 18,
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#f2f2f2",
              },
              {
                lightness: 19,
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#e9e9e9",
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
              {
                lightness: "-20",
              },
              {
                gamma: "1.00",
              },
              {
                saturation: "0",
              },
            ],
          },
        ],
      }
    );

    madeBase.partnerMap.map.setZoom(10);

    var icon = {
      path: "M-15,0a15,15 0 1,0 30,0a15,15 0 1,0 -30,0",
      fillColor: "#0698c9",
      fillOpacity: 0.7,
      anchor: new google.maps.Point(0, 0),
      strokeWeight: 0,
      scale: 1,
    };

    madeBase.partnerMap.locations = [];

    $.each($(".partners-list a"), function (index, value) {
      madeBase.partnerMap.locations.push({
        lat: parseFloat($(this).data("lat")),
        lng: parseFloat($(this).data("lng")),
        title: $("span", $(this)).text(),
        target: $(this).attr("data-featherlight"),
        label: $(this).attr("data-number"),
      });
    });

    madeBase.partnerMap.bounds = new google.maps.LatLngBounds();

    var oms = new OverlappingMarkerSpiderfier(madeBase.partnerMap.map, {
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true,
    });

    var iw = new google.maps.InfoWindow();

    var markers = madeBase.partnerMap.locations.map(function (location, i) {
      var marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: madeBase.partnerMap.map,
        text: location.title,
        label: location.label,
        icon: icon,
        target: location.target,
      });

      google.maps.event.addListener(marker, "spider_click", function (e) {
        // 'spider_click', not plain 'click'
        var iwlinktext =
          '<a href="javascript:void(0)" data-featherlight="' +
          marker.target +
          '">(' +
          marker.label +
          ") " +
          marker.text +
          "</a>";
        iw.setContent(iwlinktext);
        iw.open(madeBase.partnerMap.map, marker);
      });
      oms.addMarker(marker); // adds the marker to the spiderfier _and_ the map

      madeBase.partnerMap.bounds.extend(marker.position);

      // marker.addListener('click', function () {
      //     $.featherlight(marker.target);
      // });

      return marker;
    });

    madeBase.partnerMap.map.fitBounds(madeBase.partnerMap.bounds, 1);

    // var markerCluster = new MarkerClusterer(madeBase.partnerMap.map, markers, {
    //         imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    //     }
    // );

    madeBase.partnerMap.redraw = function () {
      google.maps.event.trigger(madeBase.partnerMap.map, "resize");
      madeBase.partnerMap.map.fitBounds(madeBase.partnerMap.bounds, 1);
    };
  };

  madeBase.popUpWindow = function (url) {
    var width = 800;
    var height = 600;

    var leftPosition, topPosition;
    leftPosition = window.screen.width / 2 - (width / 2 + 10);
    topPosition = window.screen.height / 2 - (height / 2 + 50);

    var windowFeatures =
      "status=no,height=" +
      height +
      ",width=" +
      width +
      ",resizable=yes,left=" +
      leftPosition +
      ",top=" +
      topPosition +
      ",screenX=" +
      leftPosition +
      ",screenY=" +
      topPosition +
      ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";

    window.open(url, "Social Share", windowFeatures);
  };

  madeBase.openInPopup = function () {
    $(document).on("click", ".popup", function (e) {
      e.preventDefault();
      madeBase.popUpWindow($(this).attr("href"));
    });
  };

  madeBase.openLinksInNewTab = function () {
    $("a").each(function () {
      var a = new RegExp("/" + window.location.host + "/");
      var b = new RegExp(/#|javascript/);
      if (!a.test(this.href) && !b.test(this.href)) {
        $(this).click(function (event) {
          event.preventDefault();
          event.stopPropagation();
          window.open(this.href, "_blank");
        });
      }
    });
  };

  $(document).ready(function () {
    madeBase.getPostsNews();
    madeBase.getPostsEvents();
    madeBase.tabs();
    madeBase.contentBlockSlideshow();
    madeBase.animateExpandCollapse();
    madeBase.openInPopup();
    madeBase.openLinksInNewTab();

    if ($("#partnerMap").length) {
      // madeBase.partnerMap.redraw();
      // madeBase.partnerMap.addMarkers();
      madeBase.partnerMap();

      var resizeTimer;

      $(window).on("resize", function (e) {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
          madeBase.partnerMap.redraw();
        }, 250);
      });
    }
  });
})(jQuery);
